/*@import url(https://fonts.googleapis.com/css?family=Lilita+One);

* { user-select: none; }

html { height: 100%; }
*/
/* body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  backface-visibility: hidden;
} */
/*
h1 {
  position: absolute;
  top: calc(50% - 125px);
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 20px;
  color: #1d3f59;
  font: 250px/1 'Lilita One', sans-serif;
  white-space: nowrap;
  text-align: right;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.3);
}*/

#controlsMenu{
  position: absolute;
  z-index: 5555555;
}

#loadingOverlay{
  position: absolute;
  width: 100%;
  height: 100%;
  transition: ease-in-out 2s;
}

._loading_overlay_overlay{
  position: fixed !important;

  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8) !important;
  transition: ease-in-out 2s;
}

.loadingDiv{
  position: fixed;
  height: 300px;
  width: 100%;
  text-align: center;
  display: block;
  z-index: 5000;
}

.loaderWrapper{
  position: absolute !important;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 5000;
  user-select: none;
}

.errorWrapper{
  position: absolute;
  text-align: center;
  margin-top: 50px;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 5001;
  color: red !important;
}


.pond {
  position: absolute;
  /*top: 30% ;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*background-color: rgba(0, 0, 0, 0);*/
}

.fish {
  position: absolute;

  margin: -15px 0 0 -30px;
  opacity: 0;
  /*transition: transform 10s;*/
  animation: spawn 1.5s forwards;
  user-select: none;
}
@keyframes spawn {
  100% { opacity: 1;}
}

.fish-bob {
  position: relative;

  margin-top: -20px;
  animation: bob 6s infinite;
}
@keyframes bob {
  50% { transform: translateY(40px); }
}

.fish-direction {
  position: relative;
  transition: transform .5s;
}

.fish-body {
  position: relative;
  margin-left: 10px;

  border-radius: 50%;
  border-bottom: solid 1px rgba(0, 0, 0, .2);
  transition: transform 2s ease-out;
}
/*.fish-body::before {
  content: '';
    
  
  display: block;
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-left: solid 25px orange;
  border-top: solid 15px transparent;
  border-bottom: solid 15px transparent;
}
.fish-body::after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 34px;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
}
*/
/*.fish-1 .fish-body { background-color: #d49919; }*/
/* .fish-1 .fish-body { background-image: url("https://koinkoi.io/img/Yin.png"); background-size: cover; }
.fish-1 .fish-body::before { border-left-color: #d49919; }
.fish-2 .fish-body { background-color: #74a135; }
.fish-2 .fish-body::before { border-left-color: #74a135; }
.fish-3 .fish-body { background-color: #806fad; }
.fish-3 .fish-body::before { border-left-color: #806fad; }
.fish-4 .fish-body { background-color: #d97eb6; }
.fish-4 .fish-body::before { border-left-color: #d97eb6; } */

.fish-flip .fish-direction { transform: scaleX(-1); }

.fish-spin .fish-body { transform: rotate(720deg); }

.bubble-pond {
  position: absolute;
  width: 6px;
  height: 6px;
  border: solid 1px #fff;
  border-radius: 50%;
  margin: -15px 0 0 20px;
  transform-origin: center top;
  animation: bubble 4s linear forwards;
  user-select: none;
}
@keyframes bubble {
  100% {
    transform: translateY(-200px) rotate(-1080deg);
    opacity: 0;
  }
}

.bubble-flip {
  margin-left: -28px;
  animation-name: bubble-flip;
}
@keyframes bubble-flip {
  100% {
    transform: translateY(-200px) rotate(1080deg);
    opacity: 0;
  }
}
